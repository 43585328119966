import React, { useEffect, useRef, useState } from 'react';

import VectorLayer from 'ol/layer/Vector';
import olMap from 'ol/Map';
import GeoJSON from 'ol/format/GeoJSON';
// @ts-ignore
import Toggle from 'ol-ext/control/Toggle';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';
import VectorSource from 'ol/source/Vector';
import { Geometry, GeometryCollection, Point } from 'ol/geom';
import Cluster from 'ol/source/Cluster';
import render, { Feature, MapBrowserEvent } from 'ol';

import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';

import isEqual from 'lodash.isequal';

import { useStyles } from './Themable.hooks';

import {
    fitMapToFeatures,
    makeMapFromSourceSet,
    centerOnFeature,
    transformCoords,
    LARGE_MAP_PADDING,
    fitMap,
} from './_utils';
import {
    INearEventsInteractions,
    initInteractions as initNearEventsInteractions,
    initCircle,
    updatePosition,
    updateRadius,
} from './_utils/nearEventsLayer';
import {
    createTrailLayers,
    selectFeature,
    setFeatures,
    setMarker,
    setTrail,
} from './_utils/features';
import { toggleCluster } from './_utils/clustering';
import { getCurrentLayers } from './_utils/customLayers';
import EditOverlay from '../EditOverlay/EditOverlay';

import {
    resetLastMapClickPosition,
    setMapClustering,
    storeLastMapClickPosition,
    storeLayers,
    storeNearEventsRadius,
} from '../../../../state/ui/discovery/general';
import {
    activatePreview,
    performMapFitToExtent,
    queueMapFitToExtent,
    setClustering,
} from '../../../../state/ui/discovery/snapshotting';
import { fetchTrail, selectObject } from '../../../../state/_actions';
import { stopFetchingCollection } from '../../../../state/app/fetching/lastStateFetching';
import { ICoordinates, ISourceSet } from '../../../../state/types';
import { useIsMenuOpen } from '../../../../state/ui/general/index.hooks';
import {
    useClustering,
    useFitToExtentIsPending,
    useGridCreatorLevel,
    useIsGridFullscreen,
    useLastClickCoordinate,
    usePreviewPaneId,
    useReportSet,
    useSelectedMonitoredObject,
    useSelectedMonitoredObjectTrail,
    useShouldMapBeInEditMode,
    useMapInDrawMode,
    useSnapshot,
    useSnapshotClustering,
    useSourceSet,
    useTimeLocked,
    useNearEventsRadius,
    useDataSegmentsTrails,
} from '../../selectors/index.hooks';
import { useLanguage } from '../../../../state/login/index.hooks';
import {
    useCustomLayers,
    useLayersAttributes,
} from '../../../../state/ui/discovery/general/index.hooks';

import { MOBILE } from '../../../../constants/dictionaries/ScreenSizeConst';
import { MAX_ZOOM } from '../../../../constants/map/zoom';

import { useAppDispatch } from '../../../../hooks';

import { dispatchActionFromQuery } from '../../../../helpers/dispatchActionFromQuery';
import { combineExtents } from '../../../../helpers/filterObject';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import ContextMenu from '../../../../components/ContextMenu';
import { extractActionParamsFromUrl } from '../../../../helpers/extractActionParamsFromUrl';
import { Extent } from 'ol/extent';

type TVectorLayer = VectorLayer<VectorSource<Geometry>> | null;

interface IOwnProps {
    layout?: string;
    sourceSet?: ISourceSet;
}

const DiscoveryMap = ({ layout }: IOwnProps) => {
    const classes = useStyles();
    const isMobile = useMediaQuery(MOBILE);
    const dispatch = useAppDispatch();

    const container = useRef(null);

    const [map, setMap] = useState<olMap | null>(null);
    const [clustersLayer, setClustersLayer] = useState<TVectorLayer>(null);
    const [trailLayer, setTrailLayer] = useState<TVectorLayer>(null);
    const [dataSegmentsTrailLayers, setDataSegmnentsTrailLayers] = useState<
        VectorLayer<VectorSource<Geometry>>[]
    >([]);
    const [selectionLayer, setSelectionLayer] = useState<TVectorLayer>(null);
    const [markerLayer, setMarkerLayer] = useState<TVectorLayer>(null);
    const [nearEventsLayer, setNearEventsLayer] = useState<TVectorLayer>(null);
    const [nearEventsInteractions, setNearEventsInteractions] =
        useState<INearEventsInteractions | null>(null);
    const [clusterToggle, setClusterToggle] = useState<Toggle | null>(null);
    const [clickListener, setClickListener] = useState<EventsKey | EventsKey[]>(
        []
    );
    const [moveListener, setMoveListener] = useState<EventsKey | EventsKey[]>(
        []
    );
    const [position, setPosition] = useState<ICoordinates | null>(null);

    const isMenuOpen = useIsMenuOpen();
    const snapshot = useSnapshot();
    const reportSet = useReportSet();
    const previewPaneId = usePreviewPaneId();
    const selectedMonitoredObject = useSelectedMonitoredObject();
    const selectedTrail = useSelectedMonitoredObjectTrail();
    const selectedDataSegmentTrails = useDataSegmentsTrails();
    const gridCreatorLevel = useGridCreatorLevel();
    const shouldMapBeInEditMode = useShouldMapBeInEditMode();
    const mapInDrawMode = useMapInDrawMode();
    const fitToExtentIsPending = useFitToExtentIsPending();
    const timeLocked = useTimeLocked();
    const language = useLanguage();
    const isGridFullscreen = useIsGridFullscreen();
    const layersAttributes = useLayersAttributes();
    const customLayers = useCustomLayers();
    const sourceSet = useSourceSet();
    const clustering = useClustering();
    const snapshotClustering = useSnapshotClustering();
    const lastClickCoordinate = useLastClickCoordinate();
    const nearEventsRadius = useNearEventsRadius();

    useEffect(() => {
        const {
            map: newMap,
            clustersLayer: newClustersLayer,
            trailLayer: newTrailLayer,
            selectionLayer: newSelectionLayer,
            markerLayer: newMarkerLayer,
            nearEventsLayer: newNearEventsLayer,
        } = makeMapFromSourceSet(
            sourceSet,
            container.current as unknown as HTMLDivElement,
            language,
            handleLayersChange,
            !!isMobile,
            getClustering()
        );
        setMap(newMap);
        setClustersLayer(newClustersLayer);
        setTrailLayer(newTrailLayer);
        setSelectionLayer(newSelectionLayer);
        setMarkerLayer(newMarkerLayer);
        setNearEventsLayer(newNearEventsLayer);
        setNearEventsInteractions(
            initNearEventsInteractions(
                newMap,
                newNearEventsLayer.getSource(),
                (radius, coordinates) => {
                    dispatch(storeNearEventsRadius(radius));
                    handleLastMapClickPosition(coordinates);
                }
            )
        );

        dispatch(queueMapFitToExtent());

        if (isMobile) {
            return;
        }

        dispatchActionFromQuery(extractActionParamsFromUrl(), dispatch);

        return () => {
            dispatch(stopFetchingCollection('lastStates'));
            dispatch(stopFetchingCollection(sourceSet?.id || ''));
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            dispatch(queueMapFitToExtent());
        }

        if (!map) {
            return;
        }
        const handleRightClick = (evt: MouseEvent) => {
            evt.preventDefault();

            if (mapInDrawMode) {
                return;
            }

            const coordinates = map.getEventCoordinate(evt);
            handleLastMapClickPosition(coordinates);
            setPosition({ x: Number(evt.clientX), y: Number(evt.clientY) });
        };
        map.getViewport().addEventListener('contextmenu', handleRightClick);
        map.removeControl(clusterToggle);

        const toggle = new Toggle({
            className: 'cluster-toggle',
            onToggle: (newClustering: boolean) => {
                dispatch(setClustering(newClustering, snapshot.level - 1));
                dispatch(setMapClustering(newClustering));
            },
            title: TranslationHelper.translate('Clustering'),
            active: getClustering(),
        });

        setClusterToggle(toggle);

        map.addControl(toggle);
        return () => {
            map.getViewport().removeEventListener(
                'contextmenu',
                handleRightClick
            );
        };
    }, [snapshot, map]);

    useEffect(() => {
        if (!nearEventsInteractions || !map || !nearEventsLayer) {
            return;
        }
        const { modify, draw, snap } = nearEventsInteractions;
        if (mapInDrawMode) {
            map.addInteraction(modify);
            map.addInteraction(draw);
            map.addInteraction(snap);
            if (lastClickCoordinate) {
                const coordinates = [
                    lastClickCoordinate.x,
                    lastClickCoordinate.y,
                ];
                centerOnFeature(map, coordinates);
                initCircle(
                    nearEventsLayer.getSource() as VectorSource<GeometryCollection>,
                    map.getView().getProjection(),
                    coordinates
                );
            }
        } else {
            map.removeInteraction(modify);
            map.removeInteraction(draw);
            map.removeInteraction(snap);
            nearEventsLayer.getSource()?.clear();
        }
    }, [mapInDrawMode]);

    useEffect(() => {
        if (!map || !nearEventsLayer) {
            return;
        }
        updateRadius(
            nearEventsLayer.getSource() as VectorSource<GeometryCollection>,
            map.getView().getProjection(),
            nearEventsRadius!
        );
    }, [nearEventsRadius]);

    useEffect(() => {
        if (
            !(mapInDrawMode || shouldMapBeInEditMode) ||
            !map ||
            !nearEventsLayer ||
            !lastClickCoordinate
        ) {
            return;
        }
        const coordinates = [lastClickCoordinate.x, lastClickCoordinate.y];
        centerOnFeature(map, coordinates);
        updatePosition(
            nearEventsLayer.getSource() as VectorSource<GeometryCollection>,
            map.getView().getProjection(),
            coordinates
        );
    }, [lastClickCoordinate]);

    useEffect(() => {
        handleLayersChange();
    }, [customLayers, sourceSet, map]);
    useEffect(() => {
        if (!map || !sourceSet || isMobile) {
            return;
        }

        let currentZoom = 0;

        unByKey(moveListener);

        const move = map.on('moveend', () => {
            if (clustersLayer && map) {
                const zoom = map.getView().getZoom();
                if (
                    zoom &&
                    isZoomBreakpointExceeded(zoom, currentZoom) &&
                    clustersLayer.getSource()?.getFeatures().length
                ) {
                    const source = toggleCluster(
                        getClustering(),
                        zoom,
                        clustersLayer.getSource()?.getFeatures()
                    );

                    clustersLayer.setSource(source as Cluster);
                    currentZoom = zoom;
                }
            }
        });

        setMoveListener(move);
    }, [map, sourceSet, shouldMapBeInEditMode, snapshot, snapshotClustering]);

    useEffect(() => {
        if (!map || !sourceSet) {
            return;
        }

        unByKey(clickListener);
        const click = map.on('click', handleMapClick);
        setClickListener(click);
    }, [map, sourceSet, gridCreatorLevel, shouldMapBeInEditMode]);

    useEffect(() => {
        if (map) {
            map.updateSize();
        }
    }, [isMenuOpen, layout]);

    useEffect(() => {
        if (!map || !clustersLayer || !selectionLayer) {
            return;
        }

        setFeatures(
            map,
            clustersLayer,
            sourceSet,
            shouldMapBeInEditMode,
            getClustering()
        );
        selectFeature(
            map,
            clustersLayer,
            selectionLayer,
            sourceSet?.entities?.length || 0,
            previewPaneId,
            fitToExtentIsPending && !mapInDrawMode,
            () => dispatch(performMapFitToExtent())
        );
    }, [sourceSet, previewPaneId, fitToExtentIsPending, isGridFullscreen]);

    useEffect(() => {
        if (!shouldMapBeInEditMode && markerLayer) {
            markerLayer.getSource()?.clear();
        }

        clustersLayer?.setOpacity(shouldMapBeInEditMode ? 0.5 : 1);
    }, [shouldMapBeInEditMode]);

    useEffect(() => {
        if (markerLayer && lastClickCoordinate) {
            setMarker(markerLayer, lastClickCoordinate);
        }
        if (!lastClickCoordinate) {
            markerLayer?.getSource()?.clear();
        }
    }, [lastClickCoordinate]);

    useEffect(() => {
        if (reportSet && selectedMonitoredObject) {
            dispatch(
                fetchTrail(
                    selectedMonitoredObject.id,
                    reportSet.from,
                    reportSet.to,
                    snapshot?.level
                )
            );
        }
    }, [reportSet?.id]);

    useEffect(() => {
        const zoom = map?.getView().getZoom();
        const length = clustersLayer?.getSource()?.getFeatures()?.length;
        if (length && length > 0) {
            clustersLayer?.setSource(
                toggleCluster(
                    getClustering(),
                    zoom,
                    clustersLayer?.getSource()?.getFeatures()
                )
            );
        }

        clusterToggle?.setActive(getClustering());
    }, [snapshotClustering, clustering, clusterToggle]);

    useEffect(() => {
        if (!map || !trailLayer) {
            return;
        }
        if (selectedTrail) {
            const fitToViewPort = selectedTrail?.level >= snapshot.level;

            setTrail(map, trailLayer, selectedTrail, fitToViewPort);
        } else {
            setTrail(map, trailLayer, null, true);
        }

        if (snapshotClustering === undefined) {
            setClustering(!selectedTrail, snapshot.level - 1);
        }

        clusterToggle?.setActive(getClustering());
    }, [selectedTrail]);

    useEffect(() => {
        if (!map) {
            return;
        }
        createTrailLayersAndFitMap(selectedDataSegmentTrails, map);
    }, [selectedDataSegmentTrails]);

    const createTrailLayersAndFitMap = async (
        trails: { data: GeoJSON; color: string }[],
        map: olMap
    ) => {
        //clear old trails
        if (dataSegmentsTrailLayers) {
            for (const trail of dataSegmentsTrailLayers) {
                setTrail(map, trail, null, true);
            }
        }

        if (trails) {
            const trailLayers = createTrailLayers(trails, map);
            setDataSegmnentsTrailLayers(trailLayers);

            const trailLayersExtent = trailLayers
                .map((item) => item.getSource()?.getExtent())
                .filter((item) => item) as Extent[];
            if (trailLayersExtent.length) {
                fitMap(
                    map,
                    combineExtents(trailLayersExtent),
                    LARGE_MAP_PADDING
                );
            }
        }
    };

    const handleLayersChange = (propMap?: olMap) => {
        const mapObj = propMap || map;

        if (mapObj) {
            const layers = getCurrentLayers(mapObj);
            const emptyLayers = !layers?.attributes.length;
            if (emptyLayers) {
                return;
            }

            if (
                !isEqual(layersAttributes, layers.attributes) ||
                !isEqual(customLayers, layers.layersData)
            ) {
                dispatch(storeLayers(layers));
            }
        }
    };

    const getClustering = () => {
        if (isMobile) {
            return false;
        }
        return snapshotClustering !== undefined
            ? snapshotClustering
            : clustering;
    };

    const isZoomBreakpointExceeded = (zoom: number, currentZoom: number) => {
        const maxZoomedIn = zoom >= MAX_ZOOM - 1 && currentZoom < MAX_ZOOM - 1;
        const minCurrentZoom = zoom !== MAX_ZOOM && currentZoom === 0;
        const maxCurrentZoom =
            zoom < MAX_ZOOM - 1 && currentZoom >= MAX_ZOOM - 1;
        return maxZoomedIn || minCurrentZoom || maxCurrentZoom;
    };
    const handleLastMapClickPosition = (coord: number[]) => {
        const transformedCoordinate = transformCoords(coord);

        dispatch(
            storeLastMapClickPosition(
                transformedCoordinate[0],
                transformedCoordinate[1]
            )
        );
    };

    const handleSelectOnClick = (map: olMap, pixel: number[]) => {
        const features: Array<Feature<Point> | render.Feature<Point>> = [];
        map.forEachFeatureAtPixel(pixel, (feature) => {
            features.push(feature as Feature<Point>);
        });
        const firstFeature = features[0];

        if (firstFeature) {
            const childFeatures = firstFeature.get('features');

            if (childFeatures && childFeatures.length > 1) {
                fitMapToFeatures(map, childFeatures);
            } else if (sourceSet) {
                const entityData = childFeatures
                    ? childFeatures[0].get('data')
                    : firstFeature.get('data');
                dispatch(
                    selectObject(
                        sourceSet,
                        entityData,
                        gridCreatorLevel,
                        timeLocked
                    )
                );
            }
        }
    };
    const handleMapClick = (event: MapBrowserEvent<MouseEvent>) => {
        if (!map) {
            return;
        }
        if (shouldMapBeInEditMode) {
            handleLastMapClickPosition(event.coordinate);
            return;
        }
        handleSelectOnClick(map, event.pixel);
    };

    const closeContext = () => {
        dispatch(resetLastMapClickPosition());
    };
    const handleSearchEvents = () => {
        dispatch(
            activatePreview('searchEvents', null, 'searchEvents', 'edit', {
                type: 'preview',
                level: 0,
            })
        );
    };
    return (
        <Paper className={classes.map} style={{ overflow: 'hidden' }}>
            <div ref={container} className={classes.map} />

            <div
                className={
                    'animated-crosshair pulsating-circle ' +
                    classes.animatedCrosshair
                }
                style={{
                    width: 40,
                    height: 40,
                    position: 'absolute',
                    pointerEvents: 'none',
                    boxSizing: 'border-box',
                }}
            >
                <span />
            </div>
            <ContextMenu
                position={position}
                close={closeContext}
                action={handleSearchEvents}
                buttonText="Search events nearby"
            />
            <EditOverlay hidden={shouldMapBeInEditMode} />
        </Paper>
    );
};

export default DiscoveryMap;
