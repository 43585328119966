import React from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { IMultiIcon } from '../../_utils/types';
import TranslationHelper from '../../../../helpers/TranslationHelper';

type TOwnProps = ICellRendererParams;

export const generateMaterialIcon = (params: IMultiIcon[] | string) => {
    if (typeof params === 'string') {
        return <i className="material-icons color-grey">{params}</i>;
    } else {
        return (
            <>
                {params.map((icon, index) => (
                    <i
                        key={index}
                        title={TranslationHelper.translate(icon.label)}
                        className="material-icons color-grey"
                        style={{ color: icon.color ?? 'inherit' }}
                    >
                        {icon.name}
                    </i>
                ))}
            </>
        );
    }
};

export const generateMaterialStatusIcon = (value: boolean) => {
    switch (value) {
        case true:
            return (
                <i className="material-icons" style={{ color: 'green' }}>
                    check
                </i>
            );
        case false:
            return (
                <i className="material-icons" style={{ color: 'red' }}>
                    close
                </i>
            );
        default:
            return <span></span>;
    }
};

type TAlertIcons = 'read' | 'unread';

export const generateMaterialIconForAlerts = (value: TAlertIcons) => {
    switch (value) {
        case 'read':
            return (
                <i className="material-icons" style={{ color: 'green' }}>
                    notifications
                </i>
            );
        case 'unread':
            return (
                <i className="material-icons" style={{ color: 'red' }}>
                    notifications_active
                </i>
            );
    }
};

export const IconAttachment = (props: TOwnProps) => {
    if (!props.value) {
        return <></>;
    }

    const icon = generateMaterialIcon('attach_file' as string);
    return (
        <>
            <span>{icon}</span>
        </>
    );
};

export const MultiIconCellRenderer = (props: TOwnProps) => {
    if (!props.value) {
        return <></>;
    }
    const icon = generateMaterialIcon(props.value);

    return (
        <>
            <span>{icon}</span>
        </>
    );
};

export const AlertCellRenderer = (props: TOwnProps) => {
    if (!props.value) {
        return <></>;
    }
    const icon = generateMaterialIconForAlerts(props.value as TAlertIcons);
    return (
        <>
            <span>{icon}</span>
        </>
    );
};

export const StatusIconCellRenderer = (props: TOwnProps) => {
    if (!props.value) {
        return <></>;
    }
    const icon = generateMaterialStatusIcon(props.value);

    return (
        <>
            <span>{icon}</span>
        </>
    );
};

export const IconCellRenderer = (props: TOwnProps) => {
    if (!props.value) {
        return <></>;
    }
    const icon = generateMaterialIcon(props.value as string);

    return (
        <>
            <span>{icon}</span>
        </>
    );
};
